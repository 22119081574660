.home {
    .home-menu {
        max-width: $desktop_large;
        margin: 0 auto;
        padding: 0 56px;
        @media (max-width: 800px) {
            display: none;
        }
        ul {
            margin: 0;
            padding: 56px 0 36px;
            list-style-type: none;
            li {
                font-family: $proxima;
                font-size: 18px;
                font-weight: 700;
                line-height: 1.3;
                padding: 0 40px 20px 0;
                display: inline-block;
                &:first-of-type {
                    padding-left: 0;
                }
            }
        }
    }
    .home-main-content {
        max-width: $desktop_large;
        margin: 0 auto;
        padding: 0 56px 50px;

        @media (max-width: 800px) {
            padding: 0 32px;
        }

        @media (max-width: 600px) {
            padding: 0;
        }
    }
    .main-content {
        box-sizing: border-box;
        &.homepage {
            width: $desktop_large;
            max-width: 100%;

            @media (max-width: 600px) {
                padding: 0;
            }
        }
    }

    /* features and newsletters */
    .features-and-newsletters {
        display: grid;
        grid-template-columns: 4fr 300px;
        gap: 50px;
        padding: 60px 0 10px 0;
        @media (max-width: 1200px) {
            display: block;
        }

        .latest-features-wrapper {
            @media (max-width: 600px) {
                padding: 0 20px;
            }

            h2 {
                font-family: $proxima;
                font-size: 32px;
                margin-top: 0;
            }

            .latest-features {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 50px;

                @media (max-width: 1200px) {
                    gap: 40px;
                }

                @media (max-width: 800px) {
                    grid-template-columns: repeat(2, 1fr);
                }

                @media (max-width: 600px) {
                    display: block;
                }

                .content-list-item {
                    @media (max-width: 600px) {
                        margin-bottom: 25px;
                        display: grid;
                        grid-template-columns: 1fr 126px;
                        gap: 20px;
                    }

                    .content-list-image {
                        @media (max-width: 600px) {
                            order: 1;
                        }
                        img {
                            max-width: 100%;
                            height: auto;
                        }
                    }

                    .content-list-text {
                        p.series {
                            margin-top: 5px;

                            @media (max-width: 600px) {
                                margin: -5px 0 5px;
                            }
                        }

                        p.section {
                            margin: 10px 0 5px;
                            font-family: $proxima;
                            font-weight: bold;

                            @media (max-width: 1200px) {
                                font-size: 14px;
                            }

                            @media (max-width: 600px) {
                                margin-top: 0;
                            }
                        }

                        h3 {
                            margin: 0 0 10px;
                            font-size: 25px;
                            font-weight: normal;

                            @media (max-width: 1200px) {
                                font-size: 22px;
                            }

                            @media (max-width: 600px) {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
        .home-right-newsletter {
            @media (max-width: 600px) {
                padding: 0 25px;
            }
            .leade {
                h3 {
                    margin: 0;
                    font-family: $proxima;
                    font-size: 28px;
                    display: flex;
                    align-items: center;
                    i {
                        padding-right: 24px;
                    }
                }
                p {
                    font-family: $proxima;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 1.4;
                    margin: 0;
                    padding: 0;
                    padding-top: 15px;

                    @media (max-width: 600px) {
                        display: none;
                    }

                    &.terms-info {
                        font-size: 14px;
                        padding-top: 5px;
                        a {
                            text-decoration: underline;
                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
            .twocol {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 10px;
            }
            input[type='text'] {
                border: 2px solid $black;
                width: 100%;
                padding: 10px;
                font-size: 14px;
                box-sizing: border-box;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                margin-bottom: 5px;
                &#id_first_name {
                    margin-top: 20px;
                }
            }
            input[type='email'] {
                border: 2px solid $black;
                width: 100%;
                padding: 10px;
                font-size: 14px;
                box-sizing: border-box;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                margin-bottom: 5px;
            }
            input[type='submit'] {
                margin-top: 0;
                @media (max-width: 500px) {
                    max-width: 100%;
                }
            }
            .preferenceGrid {
                display: grid;
                grid-template-columns: 20px 1fr;
                gap: 20px;
                align-items: baseline;
                margin-top: 20px;
                label {
                    font-weight: 600;
                    font-family: $proxima;
                }
                p {
                    margin-top: 0;
                    padding-top: 0;
                    color: $digital-grey;
                    font-size: 16px;
                }
            }
        }
        .home-newsletter {
            @media (max-width: 600px) {
                padding: 0 20px;
            }
        }
    }

    /* most popular section */
    .most-popular {
        padding: 80px 0;
        border-top: 1px solid $divider;

        h2 {
            font-family: $proxima;
            font-size: 32px;
            font-weight: 700;
            line-height: 1.3;
            margin-top: 0;

            @media (max-width: 600px) {
                font-size: 24px;
            }
        }

        .content-list-columns {
            @media (max-width: 900px) {
                display: block;
            }

            .content-list .content-list-item {
                .content-list-text {
                    p.dek {
                        margin: 7px 0;
                    }
                }

                @media (max-width: 600px) {
                    grid-template-columns: 1fr 126px;
                    gap: 16px;

                    .content-list-text {
                        order: 1;
                    }

                    .content-list-image {
                        order: 2;
                    }

                    p.dek {
                        display: none;
                    }
                }

                h3 {
                    font-size: 30px;

                    @media (max-width: 600px) {
                        font-size: 20px;
                    }
                }
            }

            .content-list-Right {
                @media (max-width: 600px) {
                    display: none;
                }
            }
        }
    }

    /* photo of the day */
    .photo-of-the-day {
        padding: 60px 0;
        @media (max-width: 1200px) {
            padding: 20px 0 48px;
        }

        @media (max-width: 600px) {
            padding: 0 20px 48px;
        }

        h2 {
            font-family: $proxima;
            font-size: 32px;
            margin-top: 0;

            @media (max-width: 600px) {
                margin: 20px 0;
                font-size: 24px;
            }
        }

        h3 {
            margin: 5px 0 10px;
            font-size: 44px;
            font-weight: normal;

            @media (max-width: 600px) {
                font-size: 32px;
            }
        }

        p {
            margin: 30px 0 20px;
            font-family: $proxima;
            font-size: 18px;

            @media (max-width: 600px) {
                margin: 20px 0;
                font-size: 16px;
            }
        }

        .potd-grid {
            display: grid;
            grid-template-columns: 797px 1fr;
            gap: 48px;
            align-items: center;

            @media (max-width: 1200px) {
                display: block;
            }

            img {
                width: 100%;
            }

            .potd-category {
                font-family: $proxima;
                font-size: 18px;
                font-weight: bold;
            }

            .potd-byline {
                color: $digital-grey;
                font-family: $proxima;
                font-size: 16px;

                @media (max-width: 600px) {
                    font-size: 14px;
                }
            }
            .button {
                @media (max-width: 400px) {
                    width: 100%;
                    max-width: 100%;
                }
            }
            a.black-btn {
                @media (max-width: 1200px) {
                    margin: 30px auto 0;
                }

                @media (max-width: 400px) {
                    width: 100%;
                    box-sizing: border-box;
                }
            }
        }
    }

    /* magazine & voices section */
    .magazine-and-voices {
        display: grid;
        grid-template-columns: 1fr minmax(0, 434px);
        gap: 48px;
        margin-top: 80px;
        @media (max-width: 1200px) {
            padding: 0;
            display: block;
        }

        .current-issue-story-list {
            .current-issue {
                padding: 40px 60px;
                background-color: #242424;
                display: grid;
                grid-template-columns: 7fr 6fr;
                gap: 50px;

                @media (max-width: 900px) {
                    text-align: center;
                    display: block;
                }

                @media (max-width: 600px) {
                    padding: 32px 24px;
                    display: grid;
                    grid-template-columns: 1fr;
                    gap: 15px;
                }

                .left-column {
                    margin-top: 50px;

                    @media (max-width: 600px) {
                        margin-top: 0;
                        text-align: left;
                    }

                    img#magazineLogo {
                        @media (max-width: 600px) {
                            width: 90%;
                            margin: 0;
                        }
                    }

                    .button {
                        color: $black;
                    }

                    p {
                        color: $white;
                        font-family: $minion-condensed;
                        font-size: 24px;
                        line-height: 1.4;

                        @media (max-width: 600px) {
                            margin: 0;
                            font-size: 20px;
                            text-align: left;
                        }
                    }

                    a.button {
                        width: 330px;
                        height: 23px;
                        margin: 17px 0;
                        padding: 10px;
                        background-color: white;
                        border: none;
                        font-family: $proxima;
                        font-size: 18px;
                        font-weight: bold;
                        text-align: center;
                        display: block;

                        @media (max-width: 900px) {
                            margin: 17px auto;
                        }

                        @media (max-width: 400px) {
                            width: 100%;
                            height: auto;
                            box-sizing: border-box;
                        }

                        &.subscribe-btn {
                            background-color: $sunburst-yellow;
                        }
                    }
                }

                .right-column {
                    @media (max-width: 600px) {
                        order: -1;
                    }
                    img {
                        width: 314px;
                        height: auto;

                        @media (max-width: 600px) {
                            width: 100%;
                        }
                    }
                    .issue-date {
                        max-width: 314px;
                        padding-top: 20px;
                        color: $white;
                        font-family: $proxima;
                        font-size: 18px;
                        font-weight: bold;
                        text-align: center;
                        display: block;

                        @media (max-width: 600px) {
                            text-align: left;
                            width: auto;
                            padding: 20px 0 0;
                            max-width: 100%;
                        }
                        @media (max-width: 900px) {
                            margin: auto;
                        }
                    }
                }
            }

            .story-list {
                padding: 40px 60px;
                background-color: #eee;

                @media (max-width: 1200px) {
                    padding: 40px 32px;
                }

                @media (max-width: 600px) {
                    padding: 40px 20px;
                }

                .story {
                    margin-bottom: 40px;
                    display: grid;
                    grid-template-columns: 314px 1fr;
                    gap: 40px;

                    @media (max-width: 1200px) {
                        grid-template-columns: 230px 1fr;
                    }

                    @media (max-width: 600px) {
                        margin-bottom: 32px;
                        grid-template-columns: 1fr 126px;
                        gap: 20px;
                    }

                    .story-image {
                        @media (max-width: 600px) {
                            order: 1;
                        }
                    }

                    img {
                        width: 314px;
                        height: auto;

                        @media (max-width: 1200px) {
                            width: 230px;
                        }

                        @media (max-width: 600px) {
                            width: 126px;
                        }
                    }

                    .category {
                        font-family: $proxima;
                        font-size: 18px;
                        font-weight: bold;
                        line-height: 1;
                        display: block;

                        @media (max-width: 600px) {
                            font-size: 16px;
                        }
                    }

                    .article-title {
                        margin: 10px 0;
                        color: #242424;
                        font-family: $minion-condensed;
                        font-size: 30px;
                        line-height: 1.3;
                        display: block;

                        @media (max-width: 600px) {
                            font-size: 24px;
                        }
                    }

                    .article-summary {
                        color: #242424;
                        font-family: $minion;
                        font-size: 22px;
                        line-height: 1.3;
                        display: block;

                        @media (max-width: 600px) {
                            display: none;
                        }
                    }
                }

                a.button {
                    width: 230px;
                    margin: auto;
                    padding: 15px 25px;
                    background-color: #242424;
                    color: $white;
                    font-family: $proxima;
                    font-size: 18px;
                    font-weight: bold;
                    text-align: center;
                    display: block;

                    @media (max-width: 600px) {
                        width: 100%;
                        box-sizing: border-box;
                    }
                }
            }
        }

        .voices-sidebar {
            @media (max-width: 1200px) {
                margin: 48px 30px;
                padding-top: 34px;
                border-top: 2px solid #eee;

                .widget-heading {
                    font-size: 28px;
                }
            }

            .widget-blog-list-no-images {
                .sidebar-blog-post {
                    display: grid;
                    grid-template-columns: 1fr 72px;
                    gap: 48px;

                    @media (max-width: 1400px) {
                        gap: 20px;
                    }

                    @media (max-width: 1300px) {
                        display: block;
                    }

                    img {
                        width: 72px;
                        height: 72px;

                        @media (max-width: 1300px) {
                            display: none;
                        }
                    }
                }

                .button {
                    margin: 10px auto;

                    a {
                        padding: 10px 0;
                    }
                }
            }

            .widget-section.upper {
                text-transform: none;

                a {
                    font-family: $proxima;
                    font-size: 18px;
                    line-height: 1.3;
                    letter-spacing: 0;
                }
            }

            p.title {
                margin-bottom: 35px;

                a {
                    font-size: 22px;
                    line-height: 1.3;
                }
            }

            hr {
                display: none;
            }
        }
    }

    .home-featured-stories {
        max-width: 100%;
        display: grid;
        gap: 48px;
        grid-template-columns: 1fr 300px;

        @media (max-width: 1000px) {
            grid-template-columns: 1fr;
            gap: 20px;
        }

        .special-article-feature {
            list-style-type: none;
            display: grid;
            grid-template-columns: 300px 1fr;
            gap: 48px;

            @media (max-width: 1200px) {
                grid-template-columns: 1fr 1fr;
                gap: 20px;
            }

            @media (max-width: 800px) {
                display: block;
            }

            @media (max-width: 600px) {
                padding-bottom: 30px;
                margin-bottom: 30px;
            }

            .article-list-item {
                @media (max-width: 600px) {
                    margin-bottom: 50px;
                    padding: 0 24px;
                }

                &:first-child {
                    text-align: center;
                    grid-column: 2;
                    grid-row: 1 / last-line;
                    grid-row-start: span 3;
                    position: relative;
                    z-index: 3;

                    @media (max-width: 1200px) {
                        grid-column: 1 / 3;
                    }

                    @media (max-width: 600px) {
                        text-align: left;
                    }
                }

                &.first {
                    padding: 0;

                    .article-list-text {
                        h1 {
                            margin: 0;
                            font-family: $minion-condensed;
                            font-size: 44px;
                            font-weight: 500;
                            line-height: 1.3;

                            @media (max-width: 1200px) {
                                font-size: 35px;
                            }

                            @media (max-width: 1100px) {
                                line-height: 1;
                            }

                            @media (max-width: 600px) {
                                font-size: 32px;
                                line-height: 1.3;
                            }
                        }

                        h3 {
                            margin: 0;
                            padding: 0;
                            font-family: $minion-condensed;
                            font-size: 44px;
                            font-weight: 500;
                            line-height: 1.3;
                        }

                        p {
                            &.section {
                                margin-top: 20px;
                                margin-bottom: 10px;
                                font-family: $proxima;
                                font-size: 18px;
                                font-weight: 700;
                                line-height: 1.3;
                            }
                            &.dek {
                                margin-top: 15px;
                                font-family: $minion-condensed;
                                font-size: 24px;
                                font-weight: 400;
                                line-height: 1.4;
                            }
                            &.author {
                                color: $digital-grey;
                                font-family: $proxima;
                                font-size: 16px;
                                font-weight: 500;
                                line-height: 1.4;
                                a {
                                    color: $digital-grey;
                                    font-family: $proxima;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: 1.4;
                                }
                            }
                        }

                        h1,
                        p {
                            @media (max-width: 600px) {
                                padding: 0 24px;
                            }
                        }
                    }
                }

                h3 {
                    margin: 0;
                    font-family: $minion-condensed;
                    font-size: 25px;
                    font-weight: 500;
                    line-height: 1.3;
                    margin-bottom: 5px;
                }
                p.pub-date {
                    margin: 3px 0 10px;
                }
                p.section {
                    margin: 10px 0 6px;
                    color: $black;
                    font-family: $proxima;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 1.3;
                }
            }

            img {
                width: 100%;
                height: auto;
                display: block;
            }

            .widget-call-to-action-small {
                padding: 15px 15px;
                // overflow: hidden;
                box-sizing: border-box;

                .widget-container {
                    border-top: solid 2px #e7e9ed;
                    border-bottom: solid 2px #e7e9ed;

                    h3 {
                        font-size: 29px;
                    }

                    .button {
                        margin-bottom: 20px;

                        a {
                            padding: 10px 0;
                        }
                    }
                }
            }
        }
        .smart-news {
            @media (max-width: 600px) {
                padding: 0 24px;
            }
            &.section {
                @media (max-width: 600px) {
                    padding: 0;
                }
            }
            .content-list {
                border-bottom: none;
                gap: 0;
                .pub-date {
                    margin: 3px 0 10px;
                }
            }
            h2 {
                margin: 0;
                font-family: $proxima;
                font-size: 28px;
                line-height: 0.7;
            }
            h3 {
                margin: 15px 0 5px;
                font-weight: normal;
            }
            hr {
                display: none;
            }
            .button {
                @media (max-width: 600px) {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    p.article-published {
        margin: 0 0 28px;
        color: #777;
        font-family: $proxima;

        .pub-date {
            font-weight: normal;
        }
    }

    .homepage-featured-categories {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 80px 50px;
        @media (max-width: 600px) {
            display: block;
        }
        img {
            width: 100%;
            height: auto;
        }
        .article-list-text {
            @media (max-width: 600px) {
                padding: 0 24px;
            }
        }
    }

    .full-width-with-graphic {
        margin: 60px auto;
        border-top: 1px solid $divider;
        padding-top: 60px;

        @media (max-width: 400px) {
            padding: 0 20px;
        }

        img.mobileImage {
            display: none;
        }

        @media (max-width: 600px) {
            img.desktopImage {
                display: none;
            }
            img.mobileImage {
                display: block;
            }
        }
    }

    /* special report */
    .special-report {
        display: grid;
        grid-template-columns: 1fr 300px;
        gap: 50px;
        padding: 60px 0;

        @media (max-width: 1300px) {
            gap: 20px;
        }

        @media (max-width: 1200px) {
            display: block;
        }

        @media (max-width: 900px) {
            width: auto;
            padding: 40px 20px;
        }

        .special-report-plugin {
            font-family: $proxima;

            .special-sponsored {
                a {
                    margin-left: 20px;
                    vertical-align: middle;
                }
            }
        }

        .home-special-report-container {
            @media (max-width: 1200px) {
                margin-bottom: 50px;
            }

            hr {
                height: 2px;
                background-color: $button;
                border: none;
            }

            .special-text-heading {
                margin-bottom: 20px;
            }

            h2 {
                font-family: $proxima;
                font-size: 32px;
                font-weight: bold;
                line-height: 1.3;
                margin-top: 0;

                @media (max-width: 900px) {
                    font-size: 28px;
                }
            }

            .special-plugin-container {
                display: grid;
                grid-template-columns: 3fr 2fr;
                gap: 48px;

                @media (max-width: 1300px) {
                    gap: 20px;
                }

                @media (max-width: 1000px) {
                    display: block;
                }

                .special-item.first-item img {
                    width: 100%;
                    height: auto;
                }

                .special-item.second-item {
                    h2 {
                        margin: 15px 0 0;
                        font-family: $minion-condensed;
                        font-size: 30px;
                        font-weight: 500;
                        line-height: 1.3;
                    }

                    p {
                        font-size: 22px;
                        margin-top: 20px;
                        line-height: 1.3;
                        font-family: $minion;
                        font-weight: 400;
                    }

                    .section.larger {
                        a.black-btn {
                            width: 210px;
                            padding: 12px 32px;
                            font-weight: bold;
                            display: block;

                            @media (max-width: 900px) {
                                margin: auto;
                            }
                        }
                    }
                }

                .special-item.third-item {
                    .article-content {
                        display: grid;
                        grid-template-columns: 1fr 72px;
                        gap: 48px;

                        @media (max-width: 1300px) {
                            gap: 20px;
                        }

                        @media (max-width: 800px) {
                            display: block;
                        }
                    }

                    h4 {
                        display: none;
                    }

                    h3 {
                        margin: 2px 0 30px;
                        font-family: $minion-condensed;
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 1.3;
                    }

                    .category-label {
                        font-family: $proxima;
                        font-size: 18px;
                        font-weight: bold;
                    }

                    img {
                        width: 72px;
                        margin-top: 10px;

                        @media (max-width: 800px) {
                            display: none;
                        }
                    }

                    .made-possible {
                        text-align: right;

                        p {
                            color: $black;
                            font-family: $proxima;
                            font-size: 14px;
                        }

                        img {
                            max-width: 218px;
                        }
                    }
                }

                .special-sponsored {
                    margin-top: 20px;
                    text-align: right;

                    img {
                        margin-top: 10px;
                    }
                }

                .button {
                    @media (max-width: 1000px) {
                        margin: 20px auto 0;
                    }
                }
            }
        }
    }

    /* home page video list */
    .channel-videos {
        border-top: 1px solid $divider;
        padding: 60px 0;

        @media (max-width: 600px) {
            padding: 30px 20px;
        }

        .homepage-featured-videos {
            padding: 0;
            display: block;

            h2 {
                font-family: $proxima;
                margin-top: 0;
            }

            h3 {
                margin: 5px 0;
                font-size: 30px;
                font-weight: normal;
            }

            img {
                width: 100%;
                height: auto;
                display: block;
            }

            .hub-videos {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 50px;
                @media (max-width: 800px) {
                    grid-template-columns: repeat(2, 1fr);
                    gap: 30px;
                }
                @media (max-width: 600px) {
                    grid-template-columns: 1fr;
                    gap: 50px;
                }
                .video-length {
                    color: $digital-grey;
                    font-family: $proxima;
                    font-size: 16px;
                }
            }
        }
        .button {
            margin-top: 30px;
        }
    }

    /* podcast */
    .podcast {
        padding: 60px 0;
        border-top: 1px solid $divider;

        @media (max-width: 600px) {
            padding: 30px 0;
        }

        h2 {
            font-family: $proxima;
            font-size: 32px;
            margin-top: 0;

            @media (max-width: 600px) {
                padding: 0 20px;
                font-size: 24px;
            }

            i.fa {
                margin-right: 25px;
                font-size: 30px;

                @media (max-width: 600px) {
                    margin-right: 15px;
                    font-size: 22px;
                }
            }
        }

        .podcast-summary {
            display: grid;
            grid-template-columns: 314px 1fr;
            gap: 48px;

            @media (max-width: 1000px) {
                display: block;
            }

            @media (max-width: 600px) {
                padding: 0 24px;
            }

            img {
                width: 100%;
                height: auto;

                @media (max-width: 1000px) {
                    width: 230px;
                    margin: 0 40px 30px 0;
                    float: left;
                }

                @media (max-width: 600px) {
                    width: 127px;
                    margin: 0 16px 20px 0;
                }
            }

            .podcast-desc {
                font-family: $minion-condensed;
                font-size: 24px;

                h3 {
                    font-family: $minion-condensed;
                    font-weight: normal;
                    font-size: 30px;
                    margin-bottom: 10px;

                    @media (max-width: 1000px) {
                        margin-top: 0;
                        padding-top: 40px;
                        font-size: 44px;
                    }

                    @media (max-width: 600px) {
                        padding-top: 20px;
                        font-size: 26px;
                    }
                }

                p {
                    @media (max-width: 1000px) {
                        clear: both;
                    }

                    @media (max-width: 600px) {
                        font-size: 20px;
                    }
                }

                .button.white {
                    margin-top: 30px;

                    @media (max-width: 1000px) {
                        max-width: 130px;
                        margin: auto;
                    }

                    @media (max-width: 600px) {
                        max-width: none;
                    }

                    a {
                        padding: 10px;

                        @media (max-width: 1000px) {
                            padding: 15px;
                        }
                    }
                }
            }
        }

        & > h3 {
            margin: 55px 0 15px;
            font-family: $proxima;
            font-size: 28px;

            @media (max-width: 600px) {
                padding: 0 24px;
                display: none;
            }
        }

        .podcast-latest {
            display: flex;
            gap: 48px;

            @media (max-width: 1000px) {
                gap: 40px;
            }

            @media (max-width: 800px) {
                gap: 10px;
            }

            @media (max-width: 600px) {
                display: none;
            }

            .podcast-episode {
                flex: 1;

                @media (max-width: 600px) {
                    margin: 25px 0;
                }

                img {
                    max-width: 100%;
                    height: auto;

                    @media (max-width: 600px) {
                        width: 100%;
                        display: none;
                    }
                }

                h4 {
                    margin: 6px 0;
                    font-family: $minion-condensed;
                    font-size: 30px;
                    font-weight: normal;
                    line-height: 1.3;

                    @media (max-width: 1000px) {
                        font-size: 22px;
                    }

                    @media (max-width: 800px) {
                        line-height: 1;
                    }

                    @media (max-width: 600px) {
                        padding: 0 24px;
                        font-size: 20px;
                        line-height: 1.3;
                    }
                }

                p {
                    margin: 0;
                    color: #777;
                    font-family: 'proxima-nova', sans-serif;
                    font-size: 16px;

                    @media (max-width: 600px) {
                        padding: 0 20px;
                        font-size: 14px;
                    }
                }

                &:first-of-type {
                    a img {
                        @media (max-width: 600px) {
                            display: block;
                        }
                    }

                    h4 {
                        @media (max-width: 600px) {
                            margin-top: 20px;
                            font-size: 32px;
                        }
                    }
                }
            }
        }
    }

    /* featured stories by category */
    .homepage-featured-categories {
        margin-top: 60px;
        margin-bottom: 140px;
        .home-category-stories {
            .mobile-divider {
                @media (max-width: 600px) {
                    border-bottom: 1px solid $divider;
                    padding: 0 0 44px 0;
                    margin: 0 24px 32px 24px;
                }
            }
            &.smithsonian-institution {
                .mobile-divider {
                    @media (max-width: 600px) {
                        border-bottom: 0;
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }
        h2 {
            font-family: $proxima;
            font-size: 32px;
            margin-top: 0;
            padding: 0;
            @media (max-width: 600px) {
                padding: 0 24px;
            }
        }

        h3 {
            margin: 40px 0 5px;
            font-family: $minion-condensed;
            font-size: 30px;
            font-weight: normal;
            @media (max-width: 600px) {
                margin-top: 20px;
                font-size: 20px;
                font-weight: 500;
                line-height: 1.4;
            }
        }

        p {
            margin: 0 0 20px;
            font-family: $minion;
            font-size: 24px;
            line-height: 1.4;
        }
        .first {
            h3 {
                font-family: $minion-condensed;
                font-size: 38px;
                font-weight: 500;
                line-height: 1.3;
                @media (max-width: 600px) {
                    font-size: 32px;
                }
            }
            p {
                &.summary {
                    font-size: 20px;
                    @media (max-width: 600px) {
                        display: none;
                    }
                }
            }
        }
        .pub-date {
            color: $digital-grey;
            font-size: 16px;
            font-weight: normal;
        }
        .button {
            margin-top: 40px;
            box-sizing: border-box;
        }
    }
}
